/*
 * modal-menu
 */

(function ($) {

    var modal_side = jQuery('.modal-side');
    var modal_content_item = jQuery('.modal-content-item');
    if (modal_side.length > 0) {
        modal_side.find('a').on('click', function (e) {
            e.preventDefault();
            var _this = jQuery(this);

            // remove all menu-side & menu-content active/show
            modal_side.find('li').removeClass('active');
            var modal_content_item_target = _this.attr('href');
            modal_content_item.hide().removeClass('_show');

            // active/show current menu-side & menu-content
            _this.parent().addClass('active');
            jQuery(modal_content_item_target).fadeIn(300);
        });
    }

    var modal_groups_2col = jQuery('.modal_groups_2col');
    if (modal_groups_2col.length > 0) {
        _heightline({
            itemClsName: '.modal_groups_2col ._item',
            itemPerRow: 2,
        });
    }

})(jQuery);