jQuery(document).ready(function () {
    /*****
     * smartresize when resize screen
     *****/
    _window.smartresize(function () {
        //tablet_layout();
        /* re-calc viewport Width & Height */
        viewportW = _window.width();
        viewportH = _window.height();
    });

    /* reload page when change orientation */
    if (window.addEventListener) {
        window.addEventListener("orientationchange", function () {
            window.location.reload();
        }, false);
    }

    /* check element in view */
    var in_view = jQuery('[class*="inview-"]');
    if (in_view.length > 0) {
        in_view.on('inview', function (event, isInView) {
            if (isInView)
                jQuery(this).addClass('inview-active');
        });
    }

    /* fancybox */
    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });

    var modal_btn_trigger = jQuery('.modal-btn-trigger a');
    if (modal_btn_trigger.length > 0) {
        modal_btn_trigger.on('click', function (e) {
            e.preventDefault();
            new Fancybox(
                [
                    {
                        src: modal_btn_trigger.attr('href'),
                        type: 'iframe',
                        width: '1227',
                        height: '698',
                    },
                ],
                {
                    on: {
                        done: (fancybox, slide) => {
                            jQuery(fancybox.container).addClass('model_menu_show');
                        },
                    },
                }
            );
        });
    }

    var modal_btn_trigger_inquiry = jQuery('.modal-btn-inquiry a');
    if (modal_btn_trigger_inquiry.length > 0) {
        modal_btn_trigger_inquiry.on('click', function (e) {
            e.preventDefault();
            new Fancybox(
                [
                    {
                        src: modal_btn_trigger_inquiry.attr('href'),
                        type: 'iframe',
                        width: '750',
                        height: '690',
                    },
                ],
                {
                    on: {
                        done: (fancybox, slide) => {
                            jQuery(fancybox.container).addClass('model_menu_show');
                        },
                    },
                }
            );
        });
    }

    var trigger_screenbg = jQuery('.trigger_screenbg');
    if (trigger_screenbg.length > 0 && !inIframe()) {
        Fancybox.show([
            {
                src: trigger_screenbg.attr('href'),
                type: 'iframe',
                width: '100%',
                height: '100%'
            }
        ], {
            animated: false,
            on: {
                done: (fancybox, slide) => {
                    jQuery(fancybox.container).addClass('model_screen_background');
                },
            }
        });
    }

    /* perfect scrollbar */
    if (jQuery('#demo_frame__pc_content_sidebar').length > 0 && jQuery('#demo_frame__pc_content_inner').length > 0) {
        const ps_pc_content_sidebar = new PerfectScrollbar('#demo_frame__pc_content_sidebar');
        const ps_pc_content_inner = new PerfectScrollbar('#demo_frame__pc_content_inner', {
            useBothWheelAxes: false,
            suppressScrollX: true
        });
    }

    var model_menu_disable = jQuery('.disable');
    if (model_menu_disable.length > 0) {
        model_menu_disable.find('a').on('click', function (e) {
            e.preventDefault();
        });
    }

    var button_disable = jQuery(
        'button.ant-btn.ant-btn-dashed, button.ant-btn.display_button, button.ant-btn.parts_add, button.ant-btn.cancel_button, button.ant-btn.maintenance_menu_delete_button, button.ant-btn.add_bar'
    );
    if (button_disable.length > 0) {
        button_disable.wrap('<span class="button_disable"></span>');
    };

    /* side-munu | sub-menu */
    // var trigger_side_submenu = jQuery('a._trigger_submenu');
    // if (trigger_side_submenu.length > 0) {
    //     trigger_side_submenu.on('click', function () {
    //         jQuery(this).parents('#demo_frame__pc_content_sidebar').toggleClass('_expand');
    //         jQuery(this).next('._sub_menu').slideToggle();
    //     })
    // }

    /* active side menu push | sub-menu01/sub-menu03 */
    var whole_push_screen01 = jQuery('#whole_push_screen01');
    var push_template_screen01 = jQuery('#push_template_screen01');
    if (whole_push_screen01.length > 0 || push_template_screen01.length > 0) {
        jQuery('#demo_frame__pc_content_sidebar').addClass('_expand');
        jQuery('#demo_frame__pc_content_sidebar .sidemenu05 ._sub_menu').show();
    }

    /* fix layout broken when fullstar run */
    if (jQuery('#push_template_screen01').length > 0 || jQuery('#whole_push_screen01').length > 0) {
        var demo_container_interval = setInterval(() => {
            var demo_container = jQuery('#demo_container');
            // var demo_container_offset = 0;
            // if (demo_container.offset().top < 0) {
            //     demo_container_offset = Math.abs(demo_container.offset().top);
            //     demo_container.css('top', demo_container_offset);
            // }
            demo_container.offset({ top: 0 });
        }, 1000);
    }

    if (jQuery('html[data-template="default02"]').length > 0) {
        var sidebar_btn_pc = jQuery('.sidebar-btn-pc');
        if(sidebar_btn_pc.length>0) {
            jQuery('aside').prepend(sidebar_btn_pc.html());
            sidebar_btn_pc.remove()
        }
    }

});

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
